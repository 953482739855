export const jobApply_components = {
  Personal_Information: 1,
  Address: 2,
  Family_Information: 3,
  Identification_Information: 4,
  Education: 5,
  Training: 11,
  Work_Experience: 6,
  References: 7,
  Cover_Letter: 8,
  Resume: 9,
  Payment_Voucher: 10,
};

export const Mandatory = 1;
export const Optional = 2;
export const Off = 3;
