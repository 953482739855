export const api = {
  common: {
    nationalities: "meta/nationalities",
    countries: "meta/countries",
    districts: "meta/districts",
    provinces: "meta/provinces",
    districtByProvience: "meta/districts/province",
    localBodyByDistrict: "meta/local-bodies",
    educationLevel: "meta/educations",

    companyInformation: "meta/company-information",

    uploadFiles: "common/shared/UploadFiles",
    deleteFiles: "common/shared/DeleteFiles",
  },
  job: {
    activeJobs: "job/active",
    jobDetail: "job/detail",
    apply: "job/apply",
  },
};
